import { useState } from "react";

export default function App() {
  return (
    <div className="bg-gray-900 min-h-screen">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-6 md:h-8 w-auto" src="/vsys_logo.png" alt="" />
            </a>
          </div>
        </nav>
      </header>

      <div className="relative isolate overflow-hidden ">
        <img
          src="/futuristic2.jpg"
          alt=""
          className="absolute left-20  -z-10 h-screen w-full object-cover"
        />

        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 min-h-screen flex  items-center justify-center">
          <div className="text-center flex flex-col items-center h-full">
            {/* <p className="md:text-[6rem] font-bold tracking-tight text-white text-[4rem]">
              <span className="text-[#009ad6]">V</span>
              <span className="text-[#e93943]">S</span>
              <span className="text-[#99c527]">Y</span>
              <span className="text-[#f1933a]">S</span>
          
            </p> */}
            <img className="h-12 md:h-20 w-auto" src="/vsys_logo.png" alt="" />
            <p className="mt-2 md:mt-6 text-2xl md:text-3xl leading-8 text-white animate-bounce mb-10">
              We are going{" "}
              <span className="text-sky-500 font-bold animate-bounce">
                live{" "}
              </span>{" "}
              soon!
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 md:mt-0 mb-10 text-center w-full">
          <p className="text-center text-sm leading-5 text-white">
            &copy;Virtuos Digital Ltd. All rights reserved.{" "}
            <span className="text-sky-500 font-bold">VSYS </span> is a trademark
            of Virtuos Digital Ltd.
          </p>
        </div>
        {/* <div
          className="absolute inset-x-0  -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#0d090b] to-[#06050e] opacity-20 "
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div> */}
      </div>
    </div>
  );
}
